<template>
    <setting-layout :title="$t('pages.user.setting.creator.promotion.title')">
        <v-container>
            <div>
                {{ $t("pages.user.setting.creator.promotion.description") }}
            </div>

            <div class="text-h5 mt-6">Time</div>

            <div class="cards mt-4">
                <div
                    class="card"
                    :class="{
                        'card--active': boost.minutes === MINUTES_OPTIONS['5'],
                        'card--disabled': !!currentBoost,
                    }"
                    @click="
                        !currentBoost
                            ? (boost.minutes = MINUTES_OPTIONS['5'])
                            : null
                    "
                >
                    <div class="card__icons">
                        <IconRocket />
                    </div>

                    <div class="card__title">5 minutes</div>
                    <div class="card__price">2,99 $</div>
                    <div class="card__discount" style="opacity: 0.5">
                        <div>-0%</div>
                    </div>
                </div>

                <div
                    class="card"
                    :class="{
                        'card--active': boost.minutes === MINUTES_OPTIONS['15'],
                        'card--disabled': !!currentBoost,
                    }"
                    @click="
                        !currentBoost
                            ? (boost.minutes = MINUTES_OPTIONS['15'])
                            : null
                    "
                >
                    <div class="card__icons">
                        <IconRocket />
                        <IconRocket />
                    </div>

                    <div class="card__title">15 minutes</div>
                    <div class="card__price">6,99 $</div>
                    <div class="card__discount">
                        <div>-23%</div>
                    </div>
                </div>

                <div
                    class="card"
                    :class="{
                        'card--active': boost.minutes === MINUTES_OPTIONS['30'],
                        'card--disabled': !!currentBoost,
                    }"
                    @click="
                        !currentBoost
                            ? (boost.minutes = MINUTES_OPTIONS['30'])
                            : null
                    "
                >
                    <div class="card__icons">
                        <IconRocket />
                        <IconRocket />
                        <IconRocket />
                    </div>

                    <div class="card__title">30 minutes</div>
                    <div class="card__price">9,99 $</div>
                    <div class="card__discount">
                        <div>-45%</div>
                    </div>
                </div>
            </div>

            <div class="text-h5 mt-6" @click="showTagsInfo = !showTagsInfo">
                Tags <IconInfo style="margin-bottom: -2px" />
            </div>

            <div v-if="showTagsInfo" class="mb-2 mt-1">
                Select the tags that you want to target
            </div>

            <v-expansion-panels v-if="!currentBoost">
                <checkboxes
                    :title="$t('dialogs.tags')"
                    :hint="$t('dialogs.boost_tags_hint')"
                    :data="TAG_OPTIONS"
                    :max-count="10"
                    :display-tag-title="false"
                    v-model="boost.tags"
                    @change="checkSelectedTags"
                    class="mt-5"
                />
            </v-expansion-panels>

            <div v-else class="tags">
                <div class="tags__item" v-for="tag in boost.tags" :key="tag.id">
                    {{ tag.title }}
                </div>
            </div>

            <div v-if="!hasTag && !currentBoost">
                <span class="red--text"><strong>* </strong>
                    {{ $t("pages.user.setting.creator.promotion.notags") }}
                </span>
            </div>

            <gradient-button
                v-if="!currentBoost"
                style="margin-top: 36px"
                :disable="!hasTag"
                @click="handleSubmit"
                class="px-2"
            >
                Boost
            </gradient-button>

            <div v-else class="mt-6">
                <div class="text-center" style="font-weight: 500">
                    Boost ends in
                </div>
                <div class="text-h2 orange--text text-center mt-2">
                    {{ countdown }}
                </div>
            </div>
        </v-container>
        <inform-dialog
            :text="dialogText"
            v-if="showErrorDialog"
            @close="showErrorDialog = false"
        />
    </setting-layout>
</template>

<script>
import SettingLayout from "@/layouts/app/profile/SettingLayout";
import { mapActions, mapMutations, mapState } from "vuex";
import { CURRENCY_SYMBOL } from "@/configs/constants";
import GradientButton from "@/components/app/button/GradientButton.vue";
import IconRocket from "@/assets/images/icons/rocket.svg?inline";
import Checkboxes from "@/components/app/posting/BeforePostingBlock";
import IconInfo from "@/assets/images/icons/info.svg?inline";
import InformDialog from "@/components/app/dialogs/InformDialog";
import axios from 'axios';

export default {
    name: "Promotion",
    data: () => ({
        currency: CURRENCY_SYMBOL,
        boost: {
            minutes: 15,
            tags: [],
        },
        MINUTES_OPTIONS: { 5: 5, 15: 15, 30: 30 },
        TAG_OPTIONS: [],
        showErrorDialog: false,
        currentBoost: null,
        countdown: "",
        showTagsInfo: false,
        hasTag: false,
        userStories: [],
        isStory: true
    }),
    computed: {
        ...mapState({
            user: (state) => state.user.user,
        }),
        dialogText() {
            if (this.isStory) {
                return this.$t('dialogs.content_expiring');
            } else {
                return this.$t('dialogs.no_content_warning');
            }
        },
    },
    methods: {
        ...mapMutations(["showSnackBar"]),
        ...mapActions(["getTags"]),

        handleSubmit() {
            if (this.userStories.length == 0) {
                this.isStory = false;
                this.showErrorDialog = true;
                return;
            }
            axios
                .post("/api/boost", {
                    minutes: this.boost.minutes,
                    tags: this.boost.tags,
                },  { hideLoader: true })
                .then((response) => {
                    if (response.data.status == false) {
                        this.showErrorDialog = true;
                        return;
                    }
                    this.currentBoost = response.data;
                    this.boost.tags = this.currentBoost.tags
                    this.startTimer();
                    this.showSnackBar(this.$t('dialogs.boost.saved'));
                })
                .catch((error) => {
                    console.log(error.response.data);
                    console.log(error.response.data.message);
                    this.showSnackBar(error.response.data.message);
                });
        },

        checkAvailability() {
            axios.get("/api/stories/user",  { hideLoader: true }).then((response) => {
                this.userStories = response.data.stories;
            });
        },

        checkSelectedTags() {
            this.hasTag = this.boost.tags.length > 0;
        },

        getCurrentBoost() {
            axios.get("/api/boost",  { hideLoader: true }).then((response) => {
                this.currentBoost = response.data;
                if (this.currentBoost) {
                    this.boost = {
                        minutes: this.currentBoost.minutes,
                        tags: this.currentBoost.tags,
                    };

                    this.startTimer();
                }
            });
        },

        /*
         * countdown
         */
        updateCountdown() {
            const now = new Date().getTime();

            const utcTimestamp = Date.parse(this.currentBoost.expires_at + "Z");
            const offset = new Date().getTimezoneOffset() * 60000;
            const localTimestamp = new Date(utcTimestamp - offset);
            const expiresAt = localTimestamp
                .toISOString()
                .replace("T", " ")
                .substring(0, 19);

            const distance = new Date(expiresAt) - now;

            if (distance <= 0) {
                clearInterval(this.timer);
                this.currentBoost = null;
                this.hasTag = false;
                return;
            }

            const minutes = Math.floor(
                (distance % (1000 * 60 * 60)) / (1000 * 60)
            );
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            this.countdown = `${this.pad(minutes)}:${this.pad(seconds)}`;
        },
        pad(num) {
            return num.toString().padStart(2, "0");
        },
        startTimer() {
            this.updateCountdown(); // Update immediately to avoid initial delay
            this.timer = setInterval(this.updateCountdown, 1000);
        },
    },
    beforeDestroy() {
        clearInterval(this.timer); // Clean up the interval on component destruction
    },
    async mounted() {
        this.getTags().then((response) => {
            this.TAG_OPTIONS = response;
        });
        this.checkAvailability();
        this.getCurrentBoost();
    },
    components: {
        Checkboxes,
        GradientButton,
        SettingLayout,
        IconRocket,
        IconInfo,
        InformDialog
    },
};
</script>

<style scoped lang="scss">
@import "@/sass/modules/_variables";

.cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 8px;

    .card {
        background: rgba(237, 237, 237, 0.1);
        border-radius: 8px;
        padding: 16px 8px;
        width: 100%;

        &.card--active {
            outline: 2px solid $main-red-color;
        }

        &.card--disabled {
            opacity: 0.5;
        }

        .card__icons {
            display: flex;
            justify-content: center;
            margin-bottom: 16px;

            svg {
                width: 20px;
                height: 20px;
            }
        }

        .card__title {
            text-align: center;
            margin-top: 16px;
            white-space: nowrap;
            font-weight: 500;
            margin-bottom: 8px;
            line-height: 1.5;
        }

        .card__price {
            text-align: center;
            opacity: 0.5;
        }

        .card__discount {
            display: flex;
            justify-content: center;

            div {
                background: $main-red-color;
                padding: 0 6px;
                border-radius: 8px;
                font-size: 12px;
                margin-top: 4px;
            }
        }
    }
}

.tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 8px;

    .tags__item {
        background: rgba(237, 237, 237, 0.1);
        padding: 4px 8px;
        border-radius: 8px;
        font-size: 12px;
        display: inline-block;
    }
}

.gradient-button {
    width: 100%;
    margin: 0;
}

.mdi-checkbox-marked {
    color: blue;
}

.gradient-checkbox {
    background: -webkit-linear-gradient(#eee, #333);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
</style>
